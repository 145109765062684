<div class="">
  <div class="content">
    <h1>Últimas publicaciones:</h1>
    <div *ngFor="let publication of publications">
      <div class="card" (click)="goPost(publication.postUrl)">
        <div style="width: 100%">
          <h3>
            {{ publication.title }}
          </h3>
          <div class="summary">
            <p>{{ publication.summary }}</p>
          </div>
          <app-publication-date
            [date]="publication.date"
            [createdBy]="publication.createdBy"
          ></app-publication-date>
        </div>
      </div>
    </div>
  </div>
</div>
