<div class="post">
    <div class="cover">
      <img
        src="https://cdn.dribbble.com/users/916023/screenshots/13218750/media/2ec4d752ebf8be2b28e74f285c23a64d.png?compress=1&resize=1000x750"
        alt=""
      />
    </div>
    <div class="content">
      <h1>¿Que es CURL?</h1>
      <div>
        <p>
          Es una Herramienta del terminal para trabajar con URLs (Protocolos de
          internet)
        </p>
        <p>Normalmente viene preinstalado en windows</p>
        <p>También permite copiar archivos de un computador a un servidor.</p>
        <h3>Demo rest api:</h3>
        <p>
          <a href="https://jsonplaceholder.typicode.com/"
            >https://jsonplaceholder.typicode.com/</a
          >
        </p>
        <p>
          <a href="https://reqres.in/">https://reqres.in/</a>
        </p>
  
        <h3>Comandos:</h3>
        <p><span>curl --version</span></p>
        <p><span>curl google.com</span></p>
        <p><span>curl https://jsonplaceholder.typicode.com/posts</span></p>
  
        <h3>Referencias:</h3>
        <p>
          <a href="https://www.youtube.com/watch?v=9u2qDQc6KWg"
            >https://www.youtube.com/watch?v=9u2qDQc6KWg</a
          >
        </p>
  
        <div class="tag">
          <span>Herramienta</span>
          <span>cmd</span>
        </div>
      </div>
  
      <div class="date">
        <h5>20 April 2021</h5>
        <h5>By Luis Torres</h5>
      </div>
    </div>
  </div>
  