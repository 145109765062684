import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-publication-date',
  templateUrl: './publication-date.component.html',
  styleUrls: ['./publication-date.component.scss']
})
export class PublicationDateComponent implements OnInit {

  @Input() date: Date = new Date();
  @Input() createdBy: string = '';
  public moment = moment;

  constructor() {
  }

  ngOnInit(): void {
  }

}
