import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PublicationDate } from '../shared/components/publication-date/publication-date.interface';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss']
})
export class TimelineComponent implements OnInit {


  public publications: PublicationDate[] = [
    {
      title: '¿Que es CURL?',
      postUrl: 'curl',
      createdBy: 'Luis Torres',
      date: new Date('04-20-2021'),
      summary: 'Es una Herramienta del terminal para trabajar con URLs (Protocolos de internet), Normalmente viene preinstalado en windows, También permite copiar archivos de un computador a un servidor.'
    },
    {
      title: 'S.O.L.I.D',
      postUrl: 'solid',
      createdBy: 'Luis Torres',
      date: new Date('04-21-2021'),
      summary: '​ Los principios SOLID son guías que pueden ser aplicadas en el desarrollo de software para eliminar malos diseños'
    },
    // {
    //   title: 'Fundamentos Javascript',
    //   postUrl: 'javascript-exercises',
    //   createdBy: 'Luis Torres',
    //   date: new Date('04-21-2021'),
    //   summary: 'In construction'
    // },
    // {
    //   title: 'TDD',
    //   postUrl: 'javascript-exercises',
    //   createdBy: 'Luis Torres',
    //   date: new Date(),
    //   summary: 'In construction'
    // },
    // {
    //   title: 'DDD',
    //   postUrl: 'javascript-exercises',
    //   createdBy: 'Luis Torres',
    //   date: new Date('04-20-2021'),
    //   summary: 'In construction'
    // },
  ]

  public coverUrl = "https://cdn.dribbble.com/users/916023/screenshots/13218750/media/2ec4d752ebf8be2b28e74f285c23a64d.png?compress=1&resize=1000x750";

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  public goPost(post: string) {
    this.router.navigate(['/' + post]);
  }


}
