import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CurlComponent } from './posts/curl/curl.component';
import { JavascriptExercisesComponent } from './posts/javascript-exercises/javascript-exercises.component';
import { SolidComponent } from './posts/solid/solid.component';
import { TimelineComponent } from './timeline/timeline.component';

const routes: Routes = [
  {
    path: '',
    component: TimelineComponent
  },
  {
    path: 'curl',
    component: CurlComponent
  },
  {
    path: 'solid',
    component: SolidComponent
  },
  {
    path: 'javascript-exercises',
    component: JavascriptExercisesComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
