import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PostComponent } from './shared/components/post/post.component';
import { TimelineComponent } from './timeline/timeline.component';
import { CurlComponent } from './posts/curl/curl.component';
import { CoverComponent } from './shared/components/cover/cover.component';
import { TagsComponent } from './shared/components/tags/tags.component';
import { PublicationDateComponent } from './shared/components/publication-date/publication-date.component';
import { JavascriptExercisesComponent } from './posts/javascript-exercises/javascript-exercises.component';
import { SolidComponent } from './posts/solid/solid.component';

@NgModule({
  declarations: [
    AppComponent,
    PostComponent,
    TimelineComponent,
    CurlComponent,
    CoverComponent,
    TagsComponent,
    PublicationDateComponent,
    JavascriptExercisesComponent,
    SolidComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
