import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-solid',
  templateUrl: './solid.component.html',
  styleUrls: ['./solid.component.scss']
})
export class SolidComponent implements OnInit {
  public coverUrl = "https://cdn.dribbble.com/users/916023/screenshots/13218750/media/2ec4d752ebf8be2b28e74f285c23a64d.png?compress=1&resize=1000x750";
  public tags: string[] = ['Clean Code'];
  public publicationDate: Date = new Date('04-21-2021');
  public createdBy: string = 'Luis Torres';
  constructor() { }

  ngOnInit(): void {
  }

}
