<div class="post">
  <app-cover *ngIf="coverUrl" [url]="coverUrl"></app-cover>
  <div class="content">
    <h1>¿Que es Javascript?</h1>
    <div>
      <p>
        Es una Herramienta del terminal para trabajar con URLs (Protocolos de
        internet)
      </p>
      <p>Normalmente viene preinstalado en windows</p>
      <p>También permite copiar archivos de un computador a un servidor.</p>
      <h3>Demo rest api:</h3>
      <p>
        <a href="https://jsonplaceholder.typicode.com/"
          >https://jsonplaceholder.typicode.com/</a
        >
      </p>
      <p>
        <a href="https://reqres.in/">https://reqres.in/</a>
      </p>

      <h3>Comandos:</h3>
      <p><span>curl --version</span></p>
      <p><span>curl google.com</span></p>
      <p><span>curl https://jsonplaceholder.typicode.com/posts</span></p>

      <h3>Referencias:</h3>
      <p>
        <a href="https://www.youtube.com/watch?v=9u2qDQc6KWg"
          >https://www.youtube.com/watch?v=9u2qDQc6KWg</a
        >
      </p>
    </div>

    <app-tags [tags]="tags"></app-tags>
    <app-publication-date
      [date]="publicationDate"
      [createdBy]="createdBy"
    ></app-publication-date>
  </div>
</div>
