<div class="post">
  <app-cover *ngIf="coverUrl" [url]="coverUrl"></app-cover>
  <div class="content">
    <h1>¿Que es S.O.L.I.D?</h1>
    <p>
      ​ Los principios SOLID son guías que pueden ser aplicadas en el desarrollo
      de software para eliminar malos diseños
    </p>
    <h3>Son cinco principios:</h3>
    <h4>**S**ingle Responsibility Principle.</h4>
    <p>Cada módulo debe tener una sola razón para cambiar.</p>
    <h4>**O**pen-Closed Principle.</h4>
    <p>
      “Una entidad de software debe estar abierta para su extensión, pero
      cerrada para su modificación”.
    </p>
    <h4>**L**iskov Substitution Principle.</h4>
    <p>
      “Para construir sistemas de software con partes intercambiables, esas
      partes se deben adherir a un contrato que permita que esas partes puedan
      ser reemplazadas por otras”.
    </p>
    <p>Typescript</p>
    <p>Prop Types, Eslint y Flow.</p>
    <h4>**I**nterface Segregation Principle.</h4>
    <p>“Un sistema no debe depender en cosas que no necesita”.</p>
    <p>Typescript</p>
    <p>Prop Types, Eslint y Flow.</p>
    <h4>**D**ependency Inversion Principle.</h4>
    <p>
      El código que implementa lógica de alto nivel no debe depender de código
      que implementa los detalles a bajo nivel. Ambos deben depender de
      abstracciones.
    </p>
    <p>“Nuestro código debe depender de abstracciones, no de concretos.”</p>
    <p>
      “El código de nuestra aplicación debería depender de interfaces en vez de
      funciones o clases concretas”.
    </p>

    <app-tags [tags]="tags"></app-tags>
    <app-publication-date
      [date]="publicationDate"
      [createdBy]="createdBy"
    ></app-publication-date>
  </div>
</div>
