<div class="post">
  <app-cover class="oval" *ngIf="coverUrl" [url]="coverUrl"></app-cover>
  <div class="content">
    <h1>¿Que es CURL?</h1>
    <div>
      <p>
        Es una Herramienta del terminal para trabajar con URLs (Protocolos de
        internet)
      </p>
      <p>Normalmente viene preinstalado en windows</p>
      <p>También permite copiar archivos de un computador a un servidor.</p>
      <h3>Demo rest api:</h3>
      <p>
        <a href="https://jsonplaceholder.typicode.com/"
          >https://jsonplaceholder.typicode.com/</a
        >
      </p>
      <p>
        <a href="https://reqres.in/">https://reqres.in/</a>
      </p>

      <h3>Comandos:</h3>
      <p><span>curl --version</span></p>
      <p><span>curl google.com</span></p>
      <h4>GET</h4>
      <p><span>curl https://jsonplaceholder.typicode.com/posts</span></p>
      <h4>Ínformación de la petición</h4>
      <p><span>curl https://jsonplaceholder.typicode.com/posts -v</span></p>
      <h4>Cabecera y datos</h4>
      <p><span>curl https://jsonplaceholder.typicode.com/posts -i</span></p>
      <h4>Un dato</h4>
      <p><span>curl https://jsonplaceholder.typicode.com/posts/1 -i</span></p>

      <h4>Guardar localmente</h4>
      <p>
        Curl llamada http, luego el output de la llamada se guardará en el
        archivo posts.txt
      </p>
      <p>
        <span>cd xxx</span><br />
        <span>mkdir yyy</span><br />
        <span>cd yyy</span><br />
        <span
          >curl -o posts.txt
          https://jsonplaceholder.typicode.com/posts/1-i</span
        >
        <span>curl -O https://jsonplaceholder.typicode.com/posts/1 -i</span>
      </p>

      <h4>POST</h4>
      <p>
        curl --data "title=hello world&body= this is my first post"
        https://jsonplaceholder.typicode.com/posts
      </p>
      <p>JSON example in windows use backslash \" </p>
      <p>
        curl -XPOST -H "Content-Type: application/json"
        https://jsonplaceholder.typicode.com/posts -d 
        {{exampleObject}}
      </p>

      <h4>PUT</h4>
      <p>
        curl -X PUT --data "title=title updated world&body= this is my put post"
        https://jsonplaceholder.typicode.com/posts/1 -o putrequest.txt
      </p>

      <h4>DELETE</h4>
      <p>curl -X DELETE https://jsonplaceholder.typicode.com/posts/1</p>

      <h4>FTP</h4>
      <p>curl -u lutor:password123 -T hello.pdf ftp://ftp.website.com</p>

      <h3>Referencias:</h3>
      <p>
        <a href="https://www.youtube.com/watch?v=9u2qDQc6KWg" target="_blank"
          >https://www.youtube.com/watch?v=9u2qDQc6KWg</a
        >
      </p>
    </div>

    <!-- <div
      contenteditable="false"
      class="reset-3c756112--copyButtonWrapper-ca591f6e"
    >
      <div
        aria-label="Copy"
        role="button"
        tabindex="0"
        class="reset-3c756112--touchableView-a079aa82--copyButton-4f9a9412--tooltipped-57ae51fe"
      >
        <svg
          preserveAspectRatio="xMidYMid meet"
          height="1em"
          width="1em"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke="currentColor"
          class="icon-7f6730be--text-3f89f380"
        >
          <g>
            <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
            <path
              d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"
            ></path>
          </g>
        </svg>
      </div>
    </div>

    <div class="reset-3c756112--codeBlockWrapper-56f27afc">
      <pre
        data-key="dccb1c911e264201bf6676c983d71e7e"
        spellcheck="false"
        class="reset-3c756112--codeBlock-36378b66"
      ><div class="codeLine-a3169fbc" data-key="07fc7dceb61a49f8be2b4b7fc4c0d1e0"><span data-key="64b1672a4f1649fb84fd45e4959e52ac"><span data-offset-key="64b1672a4f1649fb84fd45e4959e52ac:0">curl "http://one,two.example.com" -o "file_#1.txt"</span></span></div></pre>
    </div> -->

    <app-tags [tags]="tags"></app-tags>
    <app-publication-date
      [date]="publicationDate"
      [createdBy]="createdBy"
    ></app-publication-date>
  </div>
</div>
