import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-curl',
  templateUrl: './curl.component.html',
  styleUrls: ['./curl.component.scss']
})
export class CurlComponent implements OnInit {

  public coverUrl = "https://cdn.dribbble.com/users/916023/screenshots/13218750/media/2ec4d752ebf8be2b28e74f285c23a64d.png?compress=1&resize=1000x750";
  public tags: string[] = ['Herramienta', 'Cmd'];
  public publicationDate: Date = new Date('04-20-2021');
  public createdBy: string = 'Luis Torres';
  public exampleObject:string = "{\"title\":\"smartapp\",\"details\":[{\"date\":1606636800,\"payment\":0.0503},{\"date\":1606636860,\"payment\":0.0621}]}";

  constructor() { }

  ngOnInit(): void {
  }

}
